<template>
  <div
    v-if="display"
    class="
      d-flex
      flex-column
      align-items-center
      justify-content-start
      __justify-content-around __justify-content-evenly
    "
    style="min-height: calc(100vh - (65px * 2))"
  >
    <div class="col-12">
      <div class="row align-items-center mb-0">
        <div class="col-1 text-end  mb-5">
          <div class="display-2 so-lead">
            <svg
              v-show="pizza.vegetarian"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 16 16"
              version="1.1"
              class="so-vegi-icon rounded-circle align-baseline"
            >
              <path
                class="so-vegi-path"
                d="M 15.171875 0.375 C 15.128906 0.199219 14.988281 0.0664062 14.816406 0.0195312 C 14.636719 -0.0273438 14.453125 0.0273438 14.324219 0.15625 C 14.316406 0.167969 13.46875 1.023438 10.636719 2.109375 C 9.644531 2.492188 8.921875 3.210938 8.601562 4.140625 C 8.398438 4.734375 8.300781 5.632812 8.742188 6.785156 C 10.683594 4.53125 12.972656 3.90625 13 3.875 C 11.484375 4.8125 9.285156 7.839844 9.285156 7.839844 C 8.609375 9.390625 8.179688 10.980469 7.921875 12.308594 C 5.660156 2.179688 1.402344 0.132812 1.207031 0.0429688 C 0.953125 -0.0703125 0.660156 0.0429688 0.542969 0.292969 C 0.429688 0.542969 0.542969 0.839844 0.792969 0.957031 C 0.839844 0.976562 5.601562 3.265625 7.515625 15.578125 C 7.554688 15.820312 7.765625 16 8.011719 16 C 8.019531 16 8.03125 16 8.039062 16 C 8.296875 15.984375 8.5 15.777344 8.511719 15.523438 C 8.511719 15.472656 8.699219 11.65625 10.226562 8.179688 C 10.433594 8.210938 10.660156 8.238281 10.925781 8.238281 C 12.0625 8.238281 13.65625 7.824219 14.917969 5.90625 C 14.925781 5.894531 14.9375 5.878906 14.941406 5.863281 C 16.023438 3.773438 15.207031 0.515625 15.171875 0.375 Z M 15.171875 0.375 "
              />
            </svg>
          </div>
        </div>
        <div class="col-10 so-border-bottom d-flex justify-content-between mb-5" :style="{ borderColor: 'white' }">
          <div class="display-2 so-lead so-lead-padding ">
            {{ pizza.base }}
          </div>
          <!--
          <div class="float-end">
            <button class="align-middle btn btn-danger btn-lg so-no-padding-y" :disabled="!playlistId" @click="next">
              NEXT
            </button>
          </div>
          -->
        </div>
      </div>
    </div>
    <div v-for="(category, i) in pizza.categories" :key="i" class="col-12">
      <div class="row">
        <div
          class="col-10 offset-1 so-border-left so-border-bottom so-category"
          :style="{ borderColor: 'var(--so-bs-' + category[0] + ')' }"
        >
          <span class="fs-6 fw-lighter text-capitalize">{{ category[0] }}</span>
          <div class="display-2 so-ingredient">
            <span
              v-for="(ingredient, id) in category[1]"
              :key="id"
              class="me-1"
            >
              <template v-if="id != category[1].length - 1">
                {{ ingredient.name }},
              </template>
              <template v-else>
                {{ ingredient.name }}
              </template>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Pizza",
  components: {},
  props: {
    index: { type: Number, default: 1 },
    pizzaId: { type: String, required: true },
  },
  data() {
    return {
      display: false,
      pizza: {
        base: "",
        vegetarian: false,
        categories: [],
      },
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load: async function () {
      this.display = false;

      const { data: pizza } = await axios.get(
        `/api/rest/dao/pizza/${this.pizzaId}?_populate=ingredients,base,ingredients.category`
      );

      const ingredients = pizza.ingredients.reduce((out, ingredient) => {
        const catName = ingredient.category.name;
        if (!out[catName]) out[catName] = [];
        const { name, vegetarian, vegan, _id } = ingredient;
        out[catName].push({ name, vegetarian, vegan, _id });
        return out;
      }, {});

      this.pizza.categories = Object.entries(ingredients);
      this.pizza.base = pizza.base.name;

      this.pizza.vegetarian = pizza.ingredients.every(
        (ingredient) => ingredient.vegetarian
      );

      this.display = true;
    },
  },
};
</script>
<style scoped>
.so-vegi-icon {
  background-color: #ffffff;
  width: 7vh;
  height: 7vh;
}
.so-vegi-path {
  stroke: none;
  fill-rule: nonzero;
  fill: var(--so-bs-gray-800);
  fill-opacity: 1;
}
.so-lead {
  font-size: 10vh;
}
.so-ingredient {
  font-size: 8vh;
}
.so-category {
  min-height: 15vh;
}

.so-lead-padding {
  padding-left: 20px;
}
.so-border-bottom {
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-color: transparent;
}
.so-border-left {
  border-left-width: 20px;
  border-left-style: solid;
  border-color: transparent;
}
</style>
