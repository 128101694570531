<template>
  <div class="so-color container-fluid min-vh-100">
    <Navigation :dark="false">
      <ul v-show="playlists.length != 0" class="navbar-nav ---ms-auto mb-2 mb-lg-0">
        <li class="nav-item me-2">
          <select
            v-model="playlistId"
            class="form-select form-select-lg so-no-padding-y"
            @change="loadPlaylist"
          >
            <option value="" disabled selected>
              Select a playlist...
            </option>
            <option v-for="list in playlists" :key="list._id" :value="list._id">
              {{ list.name }}
            </option>
          </select>
        </li>
        <!--
        <li class="nav-item mx-5">
          <button class="btn btn-success btn-lg so-no-padding-y" :disabled="!playlistId" @click="next">
            VEGI
          </button>
        </li>
        -->
        <li class="nav-item mx-2">
          <button class="btn btn-danger btn-lg so-no-padding-y" :disabled="!playlistId" @click="next">
            NEXT
          </button>
        </li>
      </ul>
    </Navigation>
    <Pizza v-if="pizzaId" :key="pizzaId" :pizza-id="pizzaId" />
  </div>
</template>
<script>
import Navigation from "@/components/Navigation";
import Pizza from "@/components/player/Pizza";
import axios from "axios";

export default {
  name: "PlayerApp",
  components: {
    Navigation,
    Pizza,
  },
  data() {
    return {
      playlistHistory: [],
      playlists: [],
      playlistId: "",
      playlist: null,
      pizzaId: null,
      index: -1,
      pizza: null,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load: async function () {
      try {
        const { data: playlists } = await axios.get(`/api/rest/dao/playlist`);
        //await new Promise(resolve => setTimeout(resolve, 5000));
        this.playlists = playlists;
      } catch (err) {
        this.errors = err.response.data;
      }
    },

    loadPlaylist: async function () {
      try {
        this.index = -1;
        this.playlist = {};
        this.pizza = {};
        const { data: playlist } = await axios.get(
          `/api/rest/dao/playlist/${this.playlistId}`
        );
        this.playlist = playlist;

        await this.next();
      } catch (err) {
        console.error(err);
      }
    },
    loadPizza: async function (pizzaId) {
      const { data: pizza } = await axios.get(
        `/api/rest/dao/pizza/${pizzaId}?_populate=ingredients,base`
      );
      return pizza;
    },
    next: async function () {
      const variantsOver = 4;
      const items = this.playlist.pizzas;
      let pizzaId = items[Math.floor(Math.random() * items.length)];
      if (items.length > variantsOver) {
        const max = 4;
        let run = 0;
        while (run < max) {
          if (this.playlistHistory.includes(pizzaId)) {
            pizzaId = items[Math.floor(Math.random() * items.length)];
          }
          run++;
        }
      }
      this.playlistHistory.push(pizzaId);
      this.playlistHistory.splice(0, this.playlistHistory.length - variantsOver);
      this.pizzaId = pizzaId;
    },
  },
};
</script>
<style scoped>
.so-color {
  background-color: var(--so-bs-gray-800) !important;
  color: white;
}
.so-no-padding-y {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}
</style>

